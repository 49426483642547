<template>
  <ul class="orders-list-ul" v-if="ordersMade.data && ordersMade.data.length">
    <transition-group :name="pagerAnimationClass">
      <li v-for="order in ordersMade.data" :key="order.id">
        <a @click.prevent="selectOrderMadeLocal(order)" href="#" v-bind:class="'order-' + order.id">
          <div class="pull-left pull-left-items">
            <div class="details">
              <div><strong>Order Number: </strong> {{ order.order_number }}</div>
              <div v-if="order.client"><strong>Company: </strong> {{ order.client.company_name }}</div>
              <div v-else><strong>Company: </strong> No client data</div>
              <div><strong>Created: </strong>{{ helpers.formatDateTime(order.created_time) }}</div>
            </div>
          </div>
          <div class="pull-right pull-right-items">
            <div class="created" v-if="order.service_date_time">
              <strong>Service Date: </strong>
              {{ helpers.formatDateTime(order.service_date_time) }}
            </div>
            <div class="created" v-else><strong>No service date yet</strong></div>
            <div class="created" v-if="order.assigned_worker_id">
              <strong>Assigned Worker: </strong>
              {{ order.assigned_worker.first_name }} {{ order.assigned_worker.last_name }}
            </div>
            <span class="pull-right badge-state" v-bind:class="'state-' + order.order_state.slug">
              {{ order.order_state.name }}
            </span>
          </div>
          <div class="clearfix"></div>
        </a>
      </li>
    </transition-group>
  </ul>
</template>

<script>
import * as helpers from '@/helpers/functions.js';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['loadingList'],
  computed: {
    ...mapGetters({
      ordersMade: 'ordersMade',
      pagerAnimationClass: 'pagerAnimationClass'
    })
  },
  data() {
    return {
      helpers
    };
  },
  methods: {
    ...mapActions({
      selectOrderMade: 'selectOrderMade'
    }),
    selectOrderMadeLocal(order) {
      this.$router.push(`/orders/${order.order_number}`);
    }
  }
};
</script>

<style></style>
