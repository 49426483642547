<template>
  <div id="order-status-main-page">
    <navigation></navigation>
    <div class="dashboard-container-right">
      <div class="order-list">
        <div class="order-list-header mt-4">
          <h2 class="title">Current Orders</h2>

          <div id="buttons-wrapper" v-if="isDashboardAdmin || isAccounting">
            <!-- REFERESH BUTTON -->
            <button @click="refreshOrders" class="btn btn-default dropdown-toggle mr-2" style="width: 49px;" v-tooltip="'Refresh orders'">
              <span class="glyphicon glyphicon-refresh" style="font-size: 20px;"></span>
            </button>
            <!-- DROPDOWN MENU -->
            <div class="dropdown" v-if="!ordersMadeSelectedData.edit">
              <button
                class="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i class="glyphicon glyphicon-option-vertical"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a href="#" @click.prevent="exportExcelModal()"><i class="glyphicon glyphicon-save-file"></i> Export Order List</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div class="filter-wrappers" v-if="isDashboardAdmin">
          <div class="row">
            <div class="col-sm-6">
              <label>Search order </label>
              <v-select
                class="filter-order-number"
                @input="selectOrderNumberFilterLocal"
                v-model="searchQuery"
                :debounce="250"
                :on-search="getOrdersLocal"
                :options="optionsOrderNumbers"
                label="label"
                placeholder="Type in order number..."
              >
              </v-select>
            </div>
            <div class="col-sm-6">
              <label>Order Status</label>
              <select :value="ordersMadeFilters.status" class="form-control" @change="setStatusFilterQueryLocal($event.target.value)">
                <option value="">All</option>
                <option v-for="status in orderStates" :key="status.id" :value="status.id">{{ status.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <OrdersList :loadingList="loadingList" />

        <div v-if="!ordersMade.data" class="loader-dashboard-page"><div></div></div>

        <nav v-if="ordersMade.data && ordersMade.total > pager.pagerDisplayCount">
          <ul class="pagination pull-right">
            <li class="page-item">
              <button class="page-link btn btn-default" @click.prevent="pagerBackLocal()" :disabled="pager.currentPage === 1">
                <i class="glyphicon glyphicon-chevron-left"></i>
              </button>
            </li>

            <li class="page-item">
              <button class="page-link btn btn-default" @click.prevent="pagerNextLocal()" :disabled="pager.currentPage === pager.pagerLinks">
                <i class="glyphicon glyphicon-chevron-right"></i>
              </button>
            </li>
          </ul>
        </nav>
        <div class="clearfix"></div>
        <div class="pager-text-wrapper">
          <div class="pull-right pager-text">
            <span>{{ fromPager }} - {{ toPager }}</span> of <span>{{ ordersMade.total }}</span>
          </div>
        </div>

        <div class="well price-warning" v-if="ordersMade.data && !ordersMade.data.length">No orders found</div>

        <!-- EXPORT EXCEL MODAL COMPONENT -->
        <ExportOrderListExcel v-if="isDashboardAdmin || isAccounting" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Navigation from '@/components/generic/Navigation.vue';
import OrdersList from './components/OrdersList.vue';

import ExportOrderListExcel from './components/ExportOrderListExcel.vue';

import * as helpers from '../../../../helpers/functions.js';

export default {
  name: 'Orders',
  data() {
    return {
      searchQuery: null,
      optionsOrderNumbers: [],
      animateTime: 200,
      loadingList: null,
      helpers
    };
  },
  components: { ExportOrderListExcel, Navigation, OrdersList },
  computed: {
    ...mapGetters({
      ordersMade: 'ordersMade',
      userData: 'userData',
      orderStates: 'orderStates',
      pager: 'pager',
      pagerAnimationClass: 'pagerAnimationClass',
      ordersMadeSelected: 'ordersMadeSelected',
      ordersMadeFilters: 'ordersMadeFilters',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      isDashboardAdmin: 'isDashboardAdmin',
      isAccounting: 'isAccounting'
    }),
    fromPager: function() {
      let fromPager;
      fromPager = this.pager.currentPage * this.pager.pagerDisplayCount - this.pager.pagerDisplayCount + 1;
      return fromPager;
    },
    toPager: function() {
      let toPager;
      toPager = this.pager.currentPage * this.pager.pagerDisplayCount;
      if (toPager > this.ordersMade.total) return this.ordersMade.total;
      else return toPager;
    }
  },
  created: function() {
    //clear before making call, incase not all orders selected because of calendar
    this.clearOrdersMade();
    this.getOrdersStatesCount();
    this.getOrdersMadeLocal();
    if (this.orderStates.length == 0) this.getOrderStates();
    if (this.ordersMadeFilters.searchQuery !== '') this.searchQuery = this.ordersMadeFilters.searchQuery;
  },
  methods: {
    ...mapActions({
      getOrdersMade: 'getOrdersMade',
      getUserData: 'getUserData',
      selectOrderMade: 'selectOrderMade',
      getOrderStates: 'getOrderStates',
      setPager: 'setPager',
      setPagerAnimationClass: 'setPagerAnimationClass',
      getorderResults: 'getorderResults',
      getOrdersStatesCount: 'getOrdersStatesCount'
    }),
    ...mapMutations({
      setSearchFilterQuery: 'setSearchFilterQuery',
      setStatusFilterQuery: 'setStatusFilterQuery',
      updatePager: 'updatePager',
      clearOrdersMade: 'clearOrdersMade'
    }),
    pagerBackLocal() {
      this.setPagerAnimationClass('wizard-back');
      this.scrollToTop();
      this.loadingList = 'loading';
      setTimeout(() => {
        this.updatePager(this.pager.currentPage - 1);
        this.getOrdersMade().then(() => {
          this.loadingList = null;
        });
      }, this.animateTime);
    },
    pagerNextLocal() {
      this.setPagerAnimationClass('wizard-forward');
      this.scrollToTop();
      this.loadingList = 'loading';
      setTimeout(() => {
        this.updatePager(this.pager.currentPage + 1);
        this.getOrdersMade().then(() => {
          this.loadingList = null;
        });
      }, this.animateTime);
    },
    pagerNumberClickLocal(index) {
      if (index > this.pager.currentPage) this.setPagerAnimationClass('wizard-forward');
      else this.setPagerAnimationClass('wizard-back');
      this.$store.commit('setTransLoader', true);
      this.getOrdersMade(index).then(() => {
        this.$store.commit('setTransLoader', false);
      });
    },
    selectOrderNumberFilterLocal(searchQuery) {
      //on close selected order
      if (typeof searchQuery === 'string') return;

      if (searchQuery === null) this.setSearchFilterQuery('');
      else if (this.ordersMadeFilters.searchQuery === '') this.setSearchFilterQuery(searchQuery.value);

      this.setPagerAnimationClass('fade');
      this.updatePager(1);
      this.setStatusFilterQuery('');
      this.getOrdersMade();
    },
    getOrdersLocal(search, loading) {
      //select2
      loading(true);
      axios({
        method: 'get',
        url: `${this.$store.state.config.baseUrlApi}/api/get-orders`,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.accessToken}`
        },
        params: {
          q: search,
          page_range: this.pager.pagerDisplayCount
        }
      })
        .then(response => {
          this.optionsOrderNumbers = response.data.data.map(option => {
            let obj = {
              label: option.order_number,
              value: option.order_number
            };
            return obj;
          });
          loading(false);
        })
        .catch(error => {
          reject();
          console.log(error);
        });
    },
    setStatusFilterQueryLocal(statusId) {
      this.setPagerAnimationClass('fade');
      this.setStatusFilterQuery(statusId);
      this.updatePager(1);
      this.getOrdersMade();
    },
    scrollToTop() {
      let scrollTop =
        window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      let animateTime = 0;
      if (scrollTop > 100) {
        this.animateTime = 200;
        animateTime = this.animateTime;
        jQuery('html,body').animate({ scrollTop: 0 }, animateTime);
      } else {
        this.animateTime = 0;
      }
    },
    clearOrderSearchLocal() {
      this.searchQuery = '';
      this.updatePager(1);
      this.setSearchFilterQuery('');
      this.getOrdersMade();
    },
    getOrdersMadeLocal() {
      this.getOrdersMade()
        .then(() => {
          this.setPager();
        })
        .catch(error => {
          helpers.displayError(error + ': Orders', 'getOrdersMadeLocal', this);
        });
    },
    exportExcelModal() {
      jQuery('#exportPdfModal')
        .appendTo('body')
        .modal('show');
    },
    refreshOrders() {
      this.clearOrdersMade();
      this.getOrdersStatesCount();
      this.getOrdersMadeLocal();
    }
  }
};
</script>

<style lang="scss">
.order-list {
  .v-select .dropdown-toggle .clear {
    font-size: 27px;
  }
}
.orders-list-ul.loading {
  opacity: 0.5;
  pointer-events: none;
}
</style>
