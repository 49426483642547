<template>
  <!-- Modal -->
  <div id="exportPdfModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Export Orders List</h4>
        </div>
        <div class="modal-body">
          <div class="well-filters">
            <!-- DATE DROPDOWNS -->
            <div class="form-group date-filter-wrapper">
              <label class="date-label">Created Date: From</label>
              <date-dropdown v-model="startDate" :default="startDateDefault" :max="currentYear" min="2018" />
              <label class="date-label">Created Date: To</label>
              <date-dropdown v-model="endDate" :default="endDateDefault" :max="currentYear" min="2018" />
            </div>

            <!-- ORDER STATUS -->
            <div class="form-group">
              <label>Order Status </label>
              <select v-model="orderStatus" class="form-control">
                <option value="">All</option>
                <option v-for="status in orderStates" :key="status.id" :value="status.id">{{ status.name }}</option>
              </select>
            </div>

            <!-- ASSIGNED WORKER -->
            <div class="form-group">
              <label>Assigned Worker</label>
              <select id="select-worker" class="form-control" v-model="assignedWorker">
                <option value="" :key="null">All</option>
                <option v-for="admin in dashboardAdmins" :key="admin.id" :value="admin.id">{{ admin.first_name }} {{ admin.last_name }}</option>
              </select>
            </div>

            <!-- SORTED BY -->
            <div class="form-group">
              <label>Sort by</label>
              <select id="select-worker" class="form-control" v-model="sortBy">
                <option value="created_time" selected="selected">Date Created</option>
                <option value="client_id" selected="selected">Client</option>
              </select>
              <div class="sort-direction-wrapper">
                <button class="btn btn-info" @click="changeSortDirection()" v-if="sortDirection">
                  <i class="glyphicon glyphicon-sort-by-attributes-alt"></i>
                </button>
                <button class="btn btn-info" @click="changeSortDirection()" v-else>
                  <i class="glyphicon glyphicon-sort-by-attributes"></i>
                </button>
                <span class="text" v-if="sortDirection">Descending</span>
                <span class="text" v-else>Ascending</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="well price-warning" v-if="showMesssage" style="text-align: left">
            <div class="text">No orders found with these values</div>
          </div>
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-info" v-on:click="exportExcel()" :disabled="exporting">
            <i class="glyphicon glyphicon-save"></i> {{ exporting ? 'Exporting...' : 'Export' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DateDropdown from 'vue-date-dropdown';
import * as helpers from '@/helpers/functions.js';
import moment from 'moment';
import { HeadersExcel } from '@/store/data/headers-export-excel-file.js';

export default {
  name: 'ExportOrderListExcel',
  data() {
    return {
      startDate: '',
      endDate: '',
      orderStatus: '',
      assignedWorker: '',
      startDateDefault: moment().subtract(1, 'years').format('YYYY.MM.DD'),
      endDateDefault: moment().format('YYYY.MM.DD'),
      currentYear: moment().add(1, 'years').format('YYYY'),
      sortBy: 'created_time',
      sortDirection: true,
      exporting: false,
      headers: new HeadersExcel(),
      showMesssage: false,
    };
  },
  components: { DateDropdown },
  computed: {
    ...mapGetters({
      dashboardAdmins: 'dashboardAdmins',
      orderStates: 'orderStates',
      orderExport: 'orderExport',
    }),
    ...mapMutations({}),
  },
  methods: {
    ...mapActions({
      exportOrderListExcel: 'exportOrderListExcel',
      getDashboardAdmins: 'getDashboardAdmins',
    }),

    exportExcel() {
      this.showMesssage = false;
      this.exporting = true;
      let params = {
        startDate: moment(this.startDate + ' ' + '00:00:01', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(this.endDate + ' ' + '23:59:59', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        orderStatus: this.orderStatus,
        assignedWorker: this.assignedWorker,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection ? 'DESC' : 'ASC',
      };
      this.exportOrderListExcel(params).then(() => {
        if (this.orderExport.length == 0) {
          this.exporting = false;
          this.showMesssage = true;
        } else {
          let tableBodyData = this.mapBodyDataTable(this.orderExport);
          this.exportCSVFile(this.headers, tableBodyData, moment().format('YYYYMMDD_hhmmss') + '_Order-export');
        }
      });
    },

    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      setTimeout(() => {
        this.exporting = false;
        jQuery('#exportPdfModal').modal('hide');
      }, 600);
    },

    convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line != '') line += ',';

          line += array[i][index];
        }

        str += line + '\r\n';
      }

      return str;
    },

    changeSortDirection() {
      this.sortDirection = !this.sortDirection;
    },
    mapBodyDataTable(data) {
      let tableDataArray = [];
      data.forEach((item) => {
        tableDataArray.push({
          'Order Number': item.order_number,
          'Created Date and Time': helpers.formatDateTime(item.created_time),
          'Order Status': item.order_state.name,
          'Service Date and Time': item.service_date_time ? helpers.formatDateTime(item.service_date_time) : '',
          'Assigned Worker Name': item.assigned_worker ? item.assigned_worker.first_name + ' ' + item.assigned_worker.last_name : '',
          'Company Name': item.client.company_name,
          'Company Address': item.client.company_address,
          'Order Total Price (Afl.)': item.order_data.orderTotalPriceWithTaxes,
          'Invoice Date': item.order_data.orderInvoiceDate.formatted ? item.order_data.orderInvoiceDate.formatted : '',
          'Invoice Due Date': item.order_data.orderInvoiceDueDate ? item.order_data.orderInvoiceDueDate : '',
          'Contact Person': item.client.first_name + ' ' + item.client.last_name,
          'Phone Number': item.client.phone_number,
          Email: item.client.email,
        });
      });
      console.log(data);
      console.log(tableDataArray);
      return tableDataArray;
    },
  },
  created: function () {
    if (this.dashboardAdmins.length === 0) this.getDashboardAdmins();
  },
  destroyed: function () {
    helpers.removeModalFromBody('exportPdfModal');
  },
};
</script>

<style lang="css">
</style>
