export class HeadersExcel {
  constructor() {
    return [
      'Order Number',
      'Created Date and Time',
      'Order Status',
      'Service Date and Time',
      'Assigned Worker Name',
      'Company Name',
      'Company Address',
      'Order Total Price (Afl.)',
      'Invoice Date',
      'Invoice Due Date',
      'Contact Person',
      'Phone Number',
      'Email'
    ];
  }
}
